import React from "react";
import { useNavigate } from 'react-router-dom';

interface ServiceCardProps {
    image: string;
    title: string;
    description: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ image, title, description }) => {

    const navigate = useNavigate();

    const handleNavigate = () => {
        let path = '/solutions';
        let id: string | undefined;

        if (title === 'PIX Parcelado') {
            path = '/parcelex';
            id = 'inicio';
        } else if (title === 'Cartão Loja') {
            path = '/storeCard';
            id = 'inicio'
        } else {
            id = title;
        }

        navigate(path);
        if (id) {
            setTimeout(() => {
                const element = document.getElementById(id as string); 
                if (element) element.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }
    };

    return (
        <div className="flex flex-col w-[300px] h-[500px] bg-white rounded-lg dark:bg-sercredDarkBlueDark dark:text-white">
            <div className="flex w-[100%] h-[50%]">
                <img src={image} alt={title} className="w-[100%] h-[100%] object-cover rounded-t-lg" />
            </div>
            <div className="flex flex-col w-[100%] h-[50%] p-3 ">
                <h3 className="text-lg font-bold text-sercredDarkBlue mb-3 dark:text-white">{title}</h3>
                <p className="text-gray-600 mb-4">{description}</p>
                <button onClick={() => handleNavigate()} className="border border-1px border-sercredDarkBlue p-2 rounded-lg w-[55%] flex items-center justify-evenly">
                    Saiba mais <img src="Component 200.svg" alt="seta" />
                </button>
            </div>
        </div>
    );
};


export default ServiceCard;