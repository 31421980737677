export const faqData: { [category: string]: { id: number; question: string; answer: string }[] } = {
    Parcerias: [
        { id: 1, question: "Quais são os nossos tipos de parceria?", answer: "Temos diversos tipos de parceria, como para disponibilizar a nossa plataforma para você credenciar seus clientes, estabelecimentos, colaboradores... fechamos parcerias com correspondentes/representantes para levar as nossas soluções para novos clientes, etc." },
        { id: 2, question: "Qual a melhor parceria para mim?", answer: "Se você pretende criar uma operação própria de crédito consignado, pode utilizar de toda infraestrutura que temos e focar na captação de clientes. Se você tem um estabelecimento e quer trazer mais clientes aceitando novas formas de pagamento, basta se tornar um credenciado. Se pretende só disponibilizar os benefícios para os seus colaboradores, o melhor cadastro para você é o de cliente..." },
        { id: 3, question: "Como fechar uma parcerias conosco?", answer: "Para fechar uma parceria conosco, basta entrar em contato através de um de nossos canais de comunicação, que um especialista vai entender melhor as suas necessidades." },
    ],
    Plataformas: [
        { id: 4, question: "Quais são as nossas plataformas?", answer: "Temos a plataforma do parceiro, do cliente, do credenciado, do correspondente, do financeiro e RH e o aplicativo do colaborador." },
        { id: 5, question: "Por onde acesso?", answer: "Basta clicar no botão de 'Entrar' no canto superior direito desta página." },
        { id: 6, question: "Posso escolher o que meu colaborador vai ter acesso?", answer: "Sim, você pode escolher a quais serviços o seu colaborador terá acesso no aplicativo!" },
        { id: 7, question: "Posso personalizar com a minha marca?", answer: "Sim, as plataformas são construidas para operar em um formato white-label e se adaptar com as cores e o logotipo da sua empresa." },
    ],
    Convênio: [
        { id: 8, question: "É um convênio de saúde apenas?", answer: "Não é um convênio específico de saúde; nosso convênio dá ao seu colaborador uma linha de crédito que é descontado direto em folha e que pode ser consumido em diversos estabalecimentos." },
        { id: 9, question: "Como funciona?", answer: "Uma porcentagem do valor do salário do seu colaborador é transformada em limite do convênio e que poderá ser utilizado para consumo em estabalecimentos, ou em algum dos nossos demais serviços como o saque, sendo descontado apenas no próximo mês, direto na folha de pagamento." },
        { id: 10, question: "Quais as vantagens para minha empresa?", answer: "Se você é um estabelecimento credenciado, aceitar novas formas de pagamento acabam trazendo novos clientes para seu negócio, se você é um cliente, você traz um benefício sem custo para os seus funcionários e que ajuda eles até mesmo em sua organização financeira." },
    ],
    Saque: [
        { id: 11, question: "O que é o saque?", answer: "O saque transforma o limite do convênio em débito direto na conta bancária do seu colaborador." },
        { id: 12, question: "Qual a diferença do saque rotativo para o saque parcelado?", answer: "O saque rotativo é descontado em folha no mês posterior ao uso. O saque rotativo é uma antecipação de alguns meses do seu limite e que pode ser parcelado para ser descontado no decorrer dos meses." },
    ],
    Empréstimo: [
        { id: 13, question: "Como funciona o empréstimo consignado?", answer: "O empréstimo é simulado e solicitado pelo colaborador e a aprovação deve ser realizada pelo RH/Financeiro da empresa. Caso aprovado, o dinheiro cai direto na conta do colaborador." },
        { id: 14, question: "Como funciona o desconto em folha?", answer: "Todos os meses, o valor da parcela contratada é descontado diretamente na folha de pagamento do colaborador pelo RH." },
    ],
    Parcelex: [
        { id: 15, question: "O que é a Parcelex?", answer: "A parcelex é uma solução que ajuda o seu estabelecimento a recuperar clientes que não teriam condição de pagar pela sua solução no momento, através de um limite de crédito/financiamento liberado no momento da compra, sem burocracias e de forma rápida." },
        { id: 16, question: "Quais as vantagens de ter no meu negócio?", answer: "O risco é nosso, você recebe o pagamento, mesmo que o cliente fique inadimplente, você recupera aquele cliente que não teria condições de pagar..." },
    ],
};