import React from "react";

const logos = [
    "logo_declarei.png",
    "logo_sinapsys.png",
    "logo_maincode.png",
    "logo_parcelex.png",
    "logo_bv3.png",
    "logo_dm4.png",
    "logo_sigacred.png",
    "logo_neopag.png",
];

const PartnerCarousel = () => {
    return (
        <div className="overflow-hidden relative">
            <div className="flex animate-scroll lg:animate-scroll-desktop gap-8">
                {logos.map((logo, index) => (
                    <div key={index} className="flex-shrink-0 w-32 h-32 lg:w-44 lg:h-44">
                        <img
                            src={logo}
                            alt={`Parceiro ${index + 1}`}
                            className="w-full h-full object-contain"
                        />
                    </div>
                ))}
                {/* Duplicar os logos para criar um loop contínuo */}
                {logos.map((logo, index) => (
                    <div key={`clone-${index}`} className="flex-shrink-0 w-32 h-32 lg:w-44 lg:h-44">
                        <img
                            src={logo}
                            alt={`Parceiro duplicado ${index + 1}`}
                            className="w-full h-full object-contain"
                        />
                    </div>
                ))}
                {/* Duplicar os logos para criar um loop contínuo */}
                {logos.map((logo, index) => (
                    <div key={`clone-${index}`} className="flex-shrink-0 w-32 h-32 lg:w-44 lg:h-44">
                        <img
                            src={logo}
                            alt={`Parceiro duplicado ${index + 1}`}
                            className="w-full h-full object-contain"
                        />
                    </div>
                ))}
                {/* Duplicar os logos para criar um loop contínuo */}
                {logos.map((logo, index) => (
                    <div key={`clone-${index}`} className="flex-shrink-0 w-32 h-32 lg:w-44 lg:h-44">
                        <img
                            src={logo}
                            alt={`Parceiro duplicado ${index + 1}`}
                            className="w-full h-full object-contain"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PartnerCarousel;