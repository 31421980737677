import React, { useState, useRef } from 'react';
import PartnerCarousel from './partnerCarousel';
import ServiceCard from './serviceCard';
import { servicesData } from "../json/servicesData";
import Footer from './footer';
import Header from './header';
import { useNavigate } from 'react-router-dom';

const Home = () => {

    const carouselRef = useRef<HTMLDivElement>(null);

    // Função para deslizar horizontalmente
    const scrollCarousel = (direction: "left" | "right") => {
        if (carouselRef.current) {
            const scrollAmount = 300; // Pixels a deslizar por clique
            if (direction === "left") {
                carouselRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
            } else {
                carouselRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
            }
        }
    };

    const navigate = useNavigate();

    const handleNavigate = (path: string, id?: string) => {
        navigate(path);
        if (id) {
            setTimeout(() => {
                const element = document.getElementById(id);
                if (element) element.scrollIntoView({ behavior: 'smooth' });
            }, 100); 
        }
    };

    return (
        <div className="flex flex-col w-[100%] h-[100%] dark:bg-sercredSecondBlueDark dark:text-white">
            <div className='dark:bg-sercredSecondBlueDark dark:text-white '>
                <Header />
            </div>
            <div className='flex flex-col items-center md:flex-row md:p-3 lg:justify-evenly lg:w-[100%]'>
                <div className='text-sercredDarkBlue flex flex-col w-[85%] mt-3 mb-3 md:w-[50%] md:p-3 lg:w-[45%] lg:p-6 dark:bg-sercredSecondBlueDark dark:text-white'>
                    <h1 className='text-2xl md:text-4xl lg:text-5xl'>
                        Quer ter acesso às melhores soluções financeiras do mercado?
                    </h1>
                    <p className='flex mt-5 mb-5 md:text-xl lg:mt-10 lg:mb-10 lg:text-2xl'>
                        Ofereça a seus colaboradores um convênio multibenefícios com Saque Rotativo, Antecipação Salarial, Empréstimos Consignados, PIX Parcelado e muito mais
                    </p>
                    <button onClick={() => handleNavigate('/solutions', 'inicio')} className='border border-1px border-sercredDarkBlue p-3 rounded-lg lg:w-[45%] w-[75%] flex items-center justify-evenly'>Conheça nossas soluções  <img src="Component 200.svg" alt="image-1" /></button>
                </div>
                <div className='flex mt-3 mb-3 md:w-[50%] lg:w-[30%] lg:mb-0'>
                    <img src="image1.png" alt="image-1" className='lg:h-[100%]' />
                </div>
            </div>
            <div className='flex flex-col items-center w-[100%] h-[100%]'>
                <div className='text-[#001F40] bg-white flex flex-col w-[100%] p-6 lg:h-[33%] lg:pl-20 dark:bg-sercredSecondBlueDark dark:text-white '>
                    <h1 className='text-2xl lg:text-4xl lg:mt-5'>
                        Há mais de 12 anos ajudando a transformar negócios
                    </h1>
                    <p className='flex mt-3 mb-3 lg:text-2xl lg:mb-5'>
                        Com soluções de meios de pagamento para o pequeno, médio e grande varejista, convênio e empréstimo consignados                    </p>
                </div>
                <div className='text-[#001F40] bg-[#5da0c4d3] flex flex-col w-[100%] p-6 lg:h-[33%] lg:pl-20 dark:bg-[#5da0c491]  dark:text-white'>
                    <h1 className='text-2xl lg:text-4xl lg:mt-5'>
                        Mais de 3.000 estabelecimentos atendidos
                    </h1>
                    <p className='flex mt-3 mb-3 lg:text-2xl lg:mb-5'>
                        Com treinamentos, suporte técnico e comercial, consultorias, promoções de vendas e muito mais                    </p>
                </div>
                <div className='text-[#001F40] bg-[#08417bc6] flex flex-col w-[100%] p-6 lg:pl-20 dark:bg-sercredDarkBlueDark dark:text-white '>
                    <h1 className='text-2xl lg:text-4xl lg:mt-5'>
                        Mais de R$27 milhões de receita para nossos parceiros
                    </h1>
                    <p className='flex mt-3 mb-3 lg:text-2xl lg:mb-5'>
                        Nossa rede possui cerca de 60 mil clientes ativos gerando negócios recorrentes para nossos parceiros                    </p>
                </div>
            </div>
            <div className='flex flex-col p-3 h-[100%] w-[100%] dark:bg-[#5da0c491] '>
                <div className='text-sercredDarkBlue flex w-[100%] dark:text-white justify-center items-center lg:text-4xl text-2xl mb-3 lg:mb-5 lg:mt-10'>
                    <h2>Nossos Parceiros</h2>
                </div>
                <div className='lg:mb-5'>
                    <PartnerCarousel />
                </div>
            </div>
            <div className='flex w-[100%] bg-sercredDarkBlue lg:items-center dark:bg-sercredSecondBlueDark'>
                <button
                    className="hidden lg:flex lg:absolute lg:left-2 lg:h-[15%] lg:items-center lg:z-10 lg:transform lg:hover:bg-[#384b6fb4] lg:bg-[#30426355] lg:p-3 lg:rounded-full lg:shadow-md"
                    onClick={() => scrollCarousel("left")}
                >
                    <img src="Component 202.svg" alt="image-1" className='h-10 rotate-180' />
                </button>
                <div ref={carouselRef} className='flex w-[100%] lg:overflow-hidden overflow-x-auto mt-5 mb-5 space-x-5 scrollbar-thin lg:pl-5 lg:pr-5 pl-4 pr-4'>
                    {servicesData.services.map((service) => (
                        <div>
                            <ServiceCard
                                key={service.serviceId}
                                image={service.image}
                                title={service.title}
                                description={service.description}
                            />
                        </div>
                    ))}
                </div>
                <button
                    className="hidden lg:flex lg:absolute lg:right-2 lg:h-[15%] lg:items-center lg:z-10 lg:transform lg:hover:bg-[#384b6fb4] lg:bg-[#30426355] lg:p-3 lg:rounded-full lg:shadow-md"
                    onClick={() => scrollCarousel("right")}
                >
                    <img src="Component 202.svg" alt="image-1" className='h-10' />
                </button>
            </div>
            <div className='flex flex-col w-[100%] h-[100%] bg-white text-sercredDarkBlue p-6 lg:items-center dark:bg-sercredSecondBlueDark dark:text-white'>
                <div className='flex flex-col w-[100%] h-[50%] text-center justify-center items-center lg:mt-10'>
                    <h2 className='text-2xl mt-3 lg:text-4xl'>Tecnologia que simplifica</h2>
                    <h2 className='text-2xl mb-5 lg:text-4xl'>solução que transforma</h2>
                    <p className='lg:text-2xl lg:w-[70%] lg:mt-5'>Quer reorganizar sua vida financeira? Na Sercred, você encontra facilidade e agilidade para contratar empréstimos consignados, antecipar valores com desconto em folha e aproveitar nosso atendimento personalizado para encontrar a solução ideal para você.</p>
                </div>
                <div className='flex flex-col w-[100%] h-[50%] p-6 justify-center lg:flex-row lg:justify-evenly lg:w-[80%] lg:flex-wrap lg:mt-5'>
                    <div className='flex items-center mb-5 lg:text-2xl'>
                        <img src="marca 1.svg" alt="check icon" />
                        <p className='ml-3'>Suporte 24h</p>
                    </div>
                    <div className='flex items-center mb-5 lg:text-2xl'>
                        <img src="marca 1.svg" alt="check icon" />
                        <p className='ml-3'>Plataforma White-label</p>
                    </div>
                    <div className='flex items-center mb-5 lg:text-2xl'>
                        <img src="marca 1.svg" alt="check icon" />
                        <p className='ml-3'>Pagamento Facilitado</p>
                    </div>
                    <div className='flex items-center mb-5 lg:text-2xl'>
                        <img src="marca 1.svg" alt="check icon" />
                        <p className='ml-3'>Zero taxas</p>
                    </div>
                    <div className='flex items-center mb-5 lg:text-2xl'>
                        <img src="marca 1.svg" alt="check icon" />
                        <p className='ml-3'>Gestão simplificada e completa</p>
                    </div>
                </div>
            </div>
            <div className='flex dark:bg-sercredDarkBlueDark dark:text-white flex-col bg-sercredDarkBlue text-white w-[100%] h-[100%] lg:flex-row-reverse lg:pt-12 lg:pb-8 lg:justify-evenly'>
                <div className='flex flex-col w-[100%] lg:w-[40%] h-[40%] mt-3 mb-3 p-6 md:items-center md:text-center lg:items-start lg:text-justify lg:ml-5'>
                    <h1 className='text-2xl mb-5 lg:text-4xl'>
                        Faça parte da nossa rede
                    </h1>
                    <p className='flex mt-3 mb-8 md:w-[80%] lg:text-xl'>
                        E tenha acesso a todas as nossas plataformas e aplicativos desenhados sob medida para a sua empresa!
                    </p>
                    <button onClick={() => handleNavigate('/solutions', 'inicio')} className='border border-1px border-white p-3 rounded-lg md:w-[35%] lg:w-[55%] w-[75%] flex items-center justify-evenly'>Conheça nossas soluções  <img src="Component 202.svg" alt="image-1" /></button>
                </div>
                <div className='w-[100%] h-[60%] flex mb-10 lg:w-[50%]'>
                    <img src="Component 201.svg" alt="pc" className=' relative -left-1/3 lg:left-0' />
                </div>
            </div>
            <div className='flex w-[100%] h-[100%]'>
                <Footer/>
            </div>
        </div>
    );
};

export default Home;