import React, { useState, useRef } from 'react';
import Footer from './footer';
import Header from './header';
import { useNavigate } from 'react-router-dom';

const Parcelex = () => {
    const navigate = useNavigate();

    const handleNavigate = (path: string, id?: string) => {
        navigate(path);
        if (id) {
            setTimeout(() => {
                const element = document.getElementById(id);
                if (element) element.scrollIntoView({ behavior: 'smooth' });
            }, 100); 
        }
    };

    return (
        <div className="flex flex-col w-[100%] h-[100%]">
            <div id='inicio'>
                <Header />
            </div>
            <div className='flex dark:bg-sercredSecondBlueDark dark:text-white flex-col items-center md:flex-row md:p-3 lg:justify-evenly lg:w-[100%]'>
                <div className='text-sercredDarkBlue dark:text-white flex flex-col w-[85%] mt-3 mb-3 md:w-[50%] md:p-3 lg:w-[45%] lg:p-6'>
                    <h1 className='text-2xl md:text-4xl lg:text-5xl'>
                        Veja as vendas da sua empresa crescerem como nunca antes!
                    </h1>
                    <p className='flex mt-5 mb-5 md:text-xl lg:mt-14 lg:mb-14 lg:text-3xl'>
                        Ofereça crédito e pagamentos flexíveis para seus clientes em até 24X no PIX ou Boleto sem precisar assumir risco nenhum!
                    </p>
                    <button onClick={() => handleNavigate('/contact', 'inicio')} className='border border-1px border-sercredDarkBlue p-3 rounded-lg lg:w-[45%] w-[85%] flex items-center justify-between'>Quero conquistar novos clientes  <img src="Component 200.svg" alt="image-1" /></button>
                </div>
                <div className='flex mt-3 mb-3 md:w-[50%] lg:w-[30%] lg:mb-0'>
                    <img src="homem3.jpeg" alt="image-1" className='lg:h-[100%]' />
                </div>
            </div>
            <div className='flex flex-col items-center w-[100%] h-[100%]'>
                <div className='text-[#001F40] dark:bg-sercredDarkBlueDark dark:text-white bg-white flex flex-col w-[100%] p-6 lg:h-[33%] lg:pl-20 lg:pt-7 lg:pb-12'>
                    <h1 className='text-4xl lg:text-4xl lg:mt-5 lg:w-[80%] flex lg:leading-snug'>
                        Sabe aquele consumidor que não teria como pagar pelo seu produto ou serviço?
                    </h1>
                </div>
                <div className='text-[#001F40] bg-[#5da0c4d3]  dark:text-white flex flex-col w-[100%] p-6 lg:h-[33%] lg:flex-row lg:justify-evenly lg:flex lg:pt-7 lg:pb-8'>
                    <div className='flex flex-col justify-center items-center mb-5 lg:mb-0'>
                        <h1 className='text-8xl'>
                            <span className='text-5xl'>+</span> 40 <span className='text-5xl'>%</span>
                        </h1>
                        <p className='text-xl'>Tiket Médio</p>
                    </div>
                    <div className='flex flex-col justify-center items-center mb-5 lg:mb-0'>
                        <h1 className='text-8xl'>
                            <span className='text-5xl'>+</span> 35 <span className='text-5xl'>%</span>
                        </h1>
                        <p className='text-xl'>Taxa de Conversão</p>
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                        <h1 className='text-8xl'>
                            <span className='text-5xl'>+</span> 30 <span className='text-5xl'>%</span>
                        </h1>
                        <p className='text-xl'>Incremento de Vendas</p>
                    </div>
                </div>
                <div className='text-[#001F40] dark:bg-sercredSecondBlueDark dark:text-white bg-[#08417bc6] flex flex-col w-[100%] p-6 lg:pl-20 lg:pt-7 lg:pb-12'>
                    <p className='text-2xl lg:text-4xl lg:mt-5 lg:w-[90%] lg:leading-snug'>
                        Nossa solução traz esse consumidor de volta para o seu negócio, oferecendo um novo meio de pagamento facilitado para ele!
                    </p>
                </div>
            </div>
            <div className='flex flex-col-reverse p-3 h-[100%] w-[100%] lg:flex lg:flex-row dark:bg-sercredDarkBlueDark dark:text-white'>
                <div className='flex dark:text-white lg:pl-16 flex-col w-[100%] lg:w-[40%] lg:h-[100%] lg:flex-col lg:justify-evenly lg:flex h-[100%] mt-3 mb-3 p-6 text-[#001F40] md:items-center md:text-center lg:items-start lg:text-justify justify-center'>
                    <div className='flex items-center lg:text-2xl lg:mt-24 mb-10'>
                        <img src="percentage 1.svg" alt="check icon" />
                        <p className='ml-3'>Suporte 24h</p>
                    </div>
                    <div className='flex items-center lg:text-2xl mb-10'>
                        <img src="card 2.svg" alt="check icon" />
                        <p className='ml-3'>Plataforma White-label</p>
                    </div>
                    <div className='flex items-center lg:text-2xl mb-10'>
                        <img src="coin 3.svg" alt="check icon" />
                        <p className='ml-3'>Pagamento Facilitado</p>
                    </div>
                    <div className='flex items-center lg:text-2xl'>
                        <img src="calendar 4.svg" alt="check icon" />
                        <p className='ml-3'>Zero taxas</p>
                    </div>
                </div>
                <div className='w-[100%] h-[100%] flex lg:mb-10 lg:w-[50%] lg:relative lg:-bottom-28 lg:-right-36'>
                    <img src="panel_bank_pin_success.svg" alt="pc" className=' lg:left-0' />
                </div>
            </div>
            <div className='bg-sercredLightBlue lg:flex lg:flex-row lg:justify-evenly lg:items-center '>
                <div className='flex flex-col w-[100%] lg:w-[40%] h-[40%] mt-3 mb-3 p-6 md:items-center md:text-center lg:items-start lg:text-justify justify-evenly lg:ml-5 text-[#001F40]'>
                    <div className='flex items-center lg:text-2xl lg:mt-24 mb-10'>
                        <img src="people 5.svg" alt="check icon" />
                        <p className='ml-3'>Suporte 24h</p>
                    </div>
                    <div className='flex items-center lg:text-2xl mb-10'>
                        <img src="case 6.svg" alt="check icon" />
                        <p className='ml-3'>Plataforma White-label</p>
                    </div>
                    <div className='flex items-center lg:text-2xl mb-10'>
                        <img src="calendar 7.svg" alt="check icon" />
                        <p className='ml-3'>Pagamento Facilitado</p>
                    </div>
                    <div className='flex items-center lg:text-2xl lg:mb-24'>
                        <img src="cell 8.svg" alt="check icon" />
                        <p className='ml-3'>Zero taxas</p>
                    </div>
                </div>
                <div className='p-6 lg:p-0 w-[100%] h-[60%] lg:mb-24 flex mb-10 lg:mt-24 lg:w-[50%] flex-col justify-center text-[#001F40]'>
                    <p className='text-2xl mb-16 lg:text-5xl lg:w-[85%] flex lg:leading-normal'>
                        Ofereça uma experiência única para o seu cliente com link de pagamento, marketplace e muito mais
                    </p>
                    <button onClick={() => handleNavigate('/contact', 'inicio')} className='border border-1px border-[#001F40] p-3 rounded-lg md:w-[35%] lg:w-[40%] w-[85%] flex items-center justify-between lg:flex'>Quero conquistar novos clientes  <img src="Component 200.svg" alt="image-1" /></button>
                </div>
            </div>
            <div className='flex flex-col bg-sercredDarkBlue dark:bg-sercredSecondBlueDark dark:text-white text-white w-[100%] h-[100%] lg:flex-row-reverse lg:pt-12 lg:pb-8 lg:justify- lg:flex'>
                <div className='flex lg:mt-10 lg:mb-10 dark:text-white flex-col w-[100%] lg:w-[45%] h-[40%] mt-3 mb-3 p-6 md:items-center md:text-center lg:items-start lg:text-justify lg:ml-5'>
                    <h1 className='text-2xl mb-5 lg:text-4xl lg:w-[80%] lg:leading-normal'>
                        Acompanhe tudo em tempo real de um único lugar!
                    </h1>
                    <p className='flex mt-3 mb-8 md:w-[80%] lg:text-2xl lg:w-[80%]'>
                        Acompanhe o desempenho das vendas com a Parcelex através de relatórios detalhados e mantenha o controle das movimentações financeiras, com transparência e visão detalhada pela nossa plataforma!
                    </p>
                    <button onClick={() => handleNavigate('/contact', 'inicio')} className='border border-1px border-white p-3 rounded-lg md:w-[35%] lg:w-[40%] w-[65%] flex items-center justify-between'>Comece a vender agora!  <img src="Component 202.svg" alt="image-1" /></button>
                </div>
                <div className='w-[100%] h-[60%] flex mb-10 lg:w-[50%] lg:mt-16 '>
                    <img src="pc parcelex.png" alt="pc" className=' relative lg:left-0' />
                </div>
            </div>
            <div className='flex w-[100%] h-[100%]'>
                <Footer />
            </div>
        </div>
    );
};

export default Parcelex;