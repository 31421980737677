import React, { useEffect } from "react";
import Footer from "./footer";
import Header from "./header";
import { useNavigate } from 'react-router-dom';

const Solutions = () => {

    const navigate = useNavigate();

    const handleNavigate = (path: string, id?: string) => {
        navigate(path);
        if (id) {
            setTimeout(() => {
                const element = document.getElementById(id);
                if (element) element.scrollIntoView({ behavior: 'smooth' });
            }, 100); 
        }
    };


    return (
        <div className="flex flex-col w-[100%] h-[100%]  text-sercredDarkBlue">
            <div id="inicio">
                <Header />
            </div>
            <div id="Convênio Empresarial" className="flex dark:bg-sercredSecondBlueDark dark:text-white flex-col w-[100%] h-[100%] pt-5 pb-5 lg:flex-row lg:justify-evenly lg:items-center">
                <div className="flex flex-col p-5 lg:w-[40%]">
                    <h1 className="text-3xl mb-3 lg:text-5xl lg:mb-8">Convênio Empresarial</h1>
                    <p className="mb-5 lg:text-2xl lg:mb-8">Ofereça um cartão benefícios para seus colaboradores aproveitarem descontos e acesso a uma rede credenciada Sercred de lojas, mercados, farmácias e muito mais, sem custos para ele e com desconto direto em folha apenas no mês seguinte</p>
                    <button onClick={() => handleNavigate('/contact', 'inicio')} className='border border-1px border-sercredDarkBlue p-3 rounded-lg lg:w-[45%] w-[65%] flex items-center justify-evenly'>Quero entender melhor  <img src="Component 200.svg" alt="image-1" /></button>
                </div>
                <div className="p-5">
                    <img src="cartoes.svg" alt="image-1" className='lg:h-[100%]' />
                </div>
            </div>
            <div className="flex flex-col  w-[100%] h-[100%] dark:bg-sercredDarkBlueDark dark:text-white bg-[#5da0c4d3] pt-5 pb-5 lg:flex-row-reverse lg:justify-evenly lg:items-center">
                <div className="flex flex-col p-5 lg:w-[40%]">
                    <h1 className="text-3xl mb-3 lg:text-5xl lg:mb-8">Faça parte da nossa rede credenciada</h1>
                    <p className=" mb-5 lg:text-2xl lg:mb-8">Cadastre o seu estabelecimento para aceitar pagamentos através da sercred e de nossos parceiros e ainda ofereça o convênio para os seus colaboradores!</p>
                    <button onClick={() => handleNavigate('/contact', 'inicio')} className='border border-1px border-sercredDarkBlue p-3 rounded-lg lg:w-[45%] w-[75%] flex items-center justify-evenly'>Quero cadastrar minha loja  <img src="Component 200.svg" alt="image-1" /></button>
                </div>
                <div className="lg:relative lg:-left-10">
                    <img src="mapa.svg" alt="image-1" className='lg:h-[100%]' />
                </div>
            </div>
            <div id="Saque Rotativo" className="flex flex-col dark:bg-sercredSecondBlueDark dark:text-white w-[100%] h-[100%] pt-5 pb-5 lg:flex-row lg:justify-evenly lg:items-center">
                <div className="flex flex-col p-5 lg:w-[40%]">
                    <h1 className="text-3xl mb-3 lg:text-5xl lg:mb-8">Saque Rotativo</h1>
                    <p className=" mb-5 lg:text-2xl lg:mb-8">O saque rotativo é um benefício que permite que o colaborador transforme parte do limite do seu cartão convênio em crédito direto em sua conta bancária, dando aquele fôlego no final do mês e ajudando a organizar as contas.</p>
                    <button onClick={() => handleNavigate('/contact', 'inicio')} className='border border-1px border-sercredDarkBlue p-3 rounded-lg lg:w-[65%] w-[100%] flex items-center justify-evenly'>Quero os benefícios para minha empresa  <img src="Component 200.svg" alt="image-1" /></button>
                </div>
                <div className="p-5">
                    <img src="celulares saque.svg" alt="image-1" className='lg:h-[100%]' />
                </div>
            </div>
            <div className="flex flex-col  w-[100%] h-[100%] bg-[#08417bc6] text-white pt-5 pb-5 lg:flex-row lg:justify-evenly lg:items-center">
                <div className="flex flex-col p-5 lg:w-[40%]">
                    <h1 className="text-3xl mb-3 lg:text-5xl lg:mb-8">Quer entender melhor como funciona?</h1>
                    <p className=" mb-5 lg:text-2xl lg:mb-8">Responda nosso formulário para entendermos melhor o que se encaixa melhor para o seu negócio e um especialista nosso entrará em contato para explicar melhor sobre cada um dos serviços, funcionalidades, requisitos, etc..</p>
                    <button onClick={() => handleNavigate('/contact', 'inicio')} className='border border-1px border-white p-3 rounded-lg lg:w-[45%] w-[65%] flex items-center justify-evenly'>Responder formulário  <img src="Component 202.svg" alt="image-1" /></button>
                </div>
                <div className="p-5 lg:w-[30%]">
                    <img src="homem 01.jpeg" alt="image-1" className='lg:h-[100%] rounded-3xl' />
                </div>
            </div>
            <div id="Saque Parcelado" className="flex dark:bg-sercredDarkBlueDark dark:text-white flex-col w-[100%] h-[100%] pt-5 pb-5 lg:p-0 lg:pt-0 lg:pb-0 lg:h-[80%] lg:flex-row-reverse lg:justify-evenly lg:items-center">
                <div className="flex flex-col p-5 lg:pt-0 lg:pb-0 lg:w-[40%]">
                    <h1 className="text-3xl mb-3 lg:text-5xl lg:mb-8">Saque Parcelado / Antecipação salarial</h1>
                    <p className=" mb-5 lg:text-2xl lg:mb-8">O saque parcelado, também conhecido como Antecipação Salarial, funciona quase como o saque rotativo, mas permite que o colaborador antecipe até 3 vezes o valor do seu limite mensal do cartão convênio, também transformando em crédito na sua conta bancária e dividindo o pagamento em algumas parcelas de desconto em folha</p>
                    <button onClick={() => handleNavigate('/contact', 'inicio')} className='border border-1px border-sercredDarkBlue p-3 rounded-lg lg:w-[65%] w-[100%] flex items-center justify-evenly'>Quero os benefícios para minha empresa  <img src="Component 200.svg" alt="image-1" /></button>
                </div>
                <div className="lg:relative lg:-left-28 lg:-bottom-16">
                    <img src="celulares antecipacao.png" alt="image-1" className='lg:w-[80%]' />
                </div>
            </div>
            <div id="Empréstimo Consignado" className="flex flex-col dark:bg-sercredSecondBlueDark dark:text-white w-[100%] h-[100%] bg-sercredDarkBlue text-white pt-5 pb-5 lg:flex-row lg:justify-evenly lg:items-center">
                <div className="flex flex-col p-5 lg:w-[40%]">
                    <h1 className="text-3xl mb-3 lg:text-5xl lg:mb-8">Empréstimo consignado</h1>
                    <p className=" mb-3 lg:text-2xl lg:mb-8">O empréstimo consignado permite que o colaborador simule e solicite um empréstimo com taxas e prazos atraentes e com desconto diretamente em sua folha de pagamento.</p>
                    <p className=" mb-5 lg:text-2xl lg:mb-8">Com essa solução seus colaboradores vão conseguir ajeitar suas vidas financeiras com poucos cliques e zero burocracia!</p>
                    <button onClick={() => handleNavigate('/contact', 'inicio')} className='border border-1px border-white p-3 rounded-lg lg:w-[65%] w-[100%] flex items-center justify-evenly'>Quero os benefícios para minha empresa  <img src="Component 202.svg" alt="image-1" /></button>
                </div>
                <div className="p-5 lg:w-[30%]">
                    <img src="emprestimo_consignado.jpeg" alt="image-1" className='lg:h-[100%] rounded-3xl' />
                </div>
            </div>
            <div className="flex dark:bg-sercredDarkBlueDark dark:text-white flex-col w-[100%] h-[100%] pt-5 pb-5 lg:flex-row lg:justify-evenly lg:items-center">
                <div className="flex flex-col p-5 lg:w-[40%]">
                    <h1 className="text-3xl mb-3 lg:text-5xl lg:mb-8">Personalize tudo com a sua marca</h1>
                    <p className=" mb-5 lg:text-2xl lg:mb-8">Já imaginou ter um cartão com a sua marca? Uma plataformas modernas e intuitivas, aplicativos utilizados pelos seus colaboradores, credenciados, clientes ou correspondentes, tudo com a sua marca, cores, logotipo e muito mais!</p>
                    <button onClick={() => handleNavigate('/contact', 'inicio')} className='border border-1px border-sercredDarkBlue p-3 rounded-lg lg:w-[65%] w-[95%] flex items-center justify-evenly'>Quero entender melhor como funciona  <img src="Component 200.svg" alt="image-1" /></button>
                </div>
                <div className="flex flex-col p-5">
                    <div className="hidden lg:flex">
                        <img src="cartao 01 white label.svg" alt="image-1" className='lg:h-[100%]' />
                    </div>
                    <div>
                        <img src="cartao 02 white label.svg" alt="image-1" className='lg:h-[100%]' />
                    </div>
                </div>

            </div>
            <div className="flex flex-col dark:bg-sercredSecondBlueDark dark:text-white w-[100%] h-[100%] bg-sercredDarkBlue text-white pt-5 pb-5 lg:pt-16 lg:pb-16 lg:flex-row-reverse lg:justify-evenly lg:items-center">
                <div className="flex flex-col mb-5 p-5 lg:w-[40%]">
                    <h1 className="text-3xl mb-3 lg:text-4xl lg:mb-8">Tem mais alguma dúvida?</h1>
                    <p className=" mb-3 lg:text-2xl lg:mb-8">Entre em contato com nosso time de especialistas, responda nosso formulário e tiraremos todas as suas dúvidas!</p>
                    <p className=" mb-5 lg:text-2xl lg:mb-8">Clique no botão abaixo para acessar o nosso formulário!</p>
                    <button onClick={() => handleNavigate('/contact', 'inicio')} className='border border-1px border-white p-3 rounded-lg lg:w-[45%] w-[65%] flex items-center justify-evenly'>Responder formulário  <img src="Component 202.svg" alt="image-1" /></button>
                </div>
                <div className="p-5 relative -left-1/3 lg:left-0">
                    <img src="Component 201.svg" alt="image-1" className='lg:h-[100%]' />
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
};


export default Solutions;