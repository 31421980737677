import React, { createContext, useContext, useEffect, useState } from "react";

// Tipo do contexto
interface ThemeContextType {
    isDarkMode: boolean;
    toggleTheme: () => void;
}

// Contexto inicial
const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

// Provider
export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        // Recuperar o tema salvo no localStorage
        const savedTheme = localStorage.getItem("theme") === "dark";
        setIsDarkMode(savedTheme);
        document.documentElement.classList.toggle("dark", savedTheme);
    }, []);

    const toggleTheme = () => {
        const newTheme = isDarkMode ? "light" : "dark";
        localStorage.setItem("theme", newTheme);
        setIsDarkMode(!isDarkMode);
        document.documentElement.classList.toggle("dark", newTheme === "dark");
    };

    return (
        <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

// Hook para acessar o contexto
export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error("useTheme deve ser usado dentro de um ThemeProvider");
    }
    return context;
};
