import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from "../contexts/ThemeContext";

const Footer = () => {
    const { isDarkMode, toggleTheme } = useTheme();

    const [openDropdown, setOpenDropdown] = useState<number | null>(null);

    // Função para alternar o estado do dropdown
    const toggleDropdown = (index: number) => {
        // Se o dropdown clicado já estiver aberto, fecha ele (seta para null)
        setOpenDropdown(openDropdown === index ? null : index);
    };

    const navigate = useNavigate();

    const handleNavigate = (path: string, id?: string) => {
        navigate(path);
        if (id) {
            setTimeout(() => {
                const element = document.getElementById(id);
                if (element) element.scrollIntoView({ behavior: 'smooth' });
            }, 100); // Pequeno delay para garantir o carregamento da página
        }
    };

    return (
        <div className="flex flex-col w-[100%] h-[100%] text-white bg-sercredDarkBlue">
            <div className="flex bg-[#5da0c4d3] dark:bg-[#5da0c468] dark:text-white w-[100%] h-[50%] p-6">
                <ul className="space-y-4 w-[100%] lg:hidden ">
                    <li>
                        <button
                            className="flex items-center font-bold w-full text-left justify-between"
                            onClick={() => toggleDropdown(0)}
                        >
                            Para Empresas
                            <span className={`ml-2 transform ${openDropdown === 0 ? 'rotate-180' : ''}`}>
                                <img src="Component 202.svg" alt="image-1" className='rotate-90 w-2' />
                            </span>
                        </button>
                        {openDropdown === 0 && (
                            <ul className="ml-4 space-y-2 mt-2">
                                <li><button onClick={() => navigate('/contact')}>Seja nosso parceiro</button></li>
                                <li><button onClick={() => navigate('/contact')}>Seja nosso cliente</button></li>
                                <li><button onClick={() => navigate('/contact')}>Seja um credenciado</button></li>
                                <li><button onClick={() => navigate('/contact')}>Seja um correspondente</button></li>
                                <li><button onClick={() => navigate('/contact')}>Seja um investidor</button></li>
                            </ul>
                        )}
                    </li>
                    <li>
                        <button
                            className="flex items-center font-bold w-full text-left justify-between"
                            onClick={() => toggleDropdown(1)}
                        >
                            Para Colaboradores
                            <span className={`ml-2 transform ${openDropdown === 1 ? 'rotate-180' : ''}`}>
                                <img src="Component 202.svg" alt="image-1" className='rotate-90 w-2' />
                            </span>
                        </button>
                        {openDropdown === 1 && (
                            <ul className="ml-4 space-y-2 mt-2">
                                <li><button onClick={() => navigate('/contact')}>Recomende sua empresa</button></li>
                                <li><a href="https://play.google.com/store/search?q=sercred&c=apps&hl=pt_BR" className="hover:text-gray-300">Baixe nosso app</a></li>
                            </ul>
                        )}
                    </li>
                    <li>
                        <button
                            className="flex items-center font-bold w-full text-left justify-between"
                            onClick={() => toggleDropdown(2)}
                        >
                            Soluções
                            <span className={`ml-2 transform ${openDropdown === 2 ? 'rotate-180' : ''}`}>
                                <img src="Component 202.svg" alt="image-1" className='rotate-90 w-2' />
                            </span>
                        </button>
                        {openDropdown === 2 && (
                            <ul className="ml-4 space-y-2 mt-2">
                                <li><button onClick={() => handleNavigate('/solutions', 'inicio')}>Cartão Convênio</button></li>
                                <li><button onClick={() => handleNavigate('/solutions', 'Saque Rotativo')}>Saque Rotativo</button></li>
                                <li><button onClick={() => handleNavigate('/solutions', 'Saque Parcelado')}>Antecipação Salarial</button></li>
                                <li><button onClick={() => handleNavigate('/solutions', 'Empréstimo Consignado')}>Empréstimo Consignado</button></li>
                                <li><button onClick={() => handleNavigate('/parcelex', 'inicio')}>PIX Parcelado</button></li>
                                <li><button onClick={() => handleNavigate('/storeCard', 'inicio')}>Cartão Loja</button></li>
                            </ul>
                        )}
                    </li>
                    <li>
                        <button
                            className="flex items-center font-bold w-full text-left justify-between"
                            onClick={() => toggleDropdown(3)}
                        >
                            Acesse já
                            <span className={`ml-2 transform ${openDropdown === 3 ? 'rotate-180' : ''}`}>
                                <img src="Component 202.svg" alt="image-1" className='rotate-90 w-2' />
                            </span>
                        </button>
                        {openDropdown === 3 && (
                            <ul className="ml-4 space-y-2 mt-2">
                                <li><button onClick={() => navigate('/login')}>Plataforma do Parceiro</button></li>
                                <li><button onClick={() => navigate('/login')}>Plataforma do Cliente</button></li>
                                <li><button onClick={() => navigate('/login')}>Plataforma do Credenciado</button></li>
                                <li><button onClick={() => navigate('/login')}>Plataforma do RH</button></li>
                                <li><button onClick={() => navigate('/login')}>Plataforma do Financeiro</button></li>
                                <li><button onClick={() => navigate('/login')}>Plataforma do Correspondente</button></li>
                                <li><button onClick={() => navigate('/login')}>App web do Colaborador</button></li>
                                <li><a href="https://centrax-v3.parcelex.com.br/auth/login" className="hover:text-gray-300">Parcelex</a></li>
                                <li><a href="https://siga.sigacred.com.br/?op=eJwzNDAwBgAB6wDF&or=eJxLzslMzStJBQALfALl">Cartão Loja</a></li>
                            </ul>
                        )}
                    </li>
                    <li>
                        <button
                            className="flex items-center font-bold w-full text-left justify-between"
                            onClick={() => toggleDropdown(4)}
                        >
                            Institucional
                            <span className={`ml-2 transform ${openDropdown === 4 ? 'rotate-180' : ''}`}>
                                <img src="Component 202.svg" alt="image-1" className='rotate-90 w-2' />
                            </span>
                        </button>
                        {openDropdown === 4 && (
                            <ul className="ml-4 space-y-2 mt-2">
                                <li><button onClick={() => navigate('/about')}>Quem somos</button></li>
                                <li><button onClick={() => navigate('/solutions')}>Nossas soluções</button></li>
                            </ul>
                        )}
                    </li>
                    <li>
                        <button
                            className="flex items-center font-bold w-full text-left justify-between"
                            onClick={() => toggleDropdown(5)}
                        >
                            Atendimento
                            <span className={`ml-2 transform ${openDropdown === 5 ? 'rotate-180' : ''}`}>
                                <img src="Component 202.svg" alt="image-1" className='rotate-90 w-2' />
                            </span>
                        </button>
                        {openDropdown === 5 && (
                            <ul className="ml-4 space-y-2 mt-2">
                                <li><button onClick={() => navigate('/contact')}>Suporte</button></li>
                                <li><button onClick={() => navigate('/contact')}>Canais de atendimento</button></li>
                            </ul>
                        )}
                    </li>
                </ul>
                <ul className='hidden lg:visible lg:text-white lg:flex lg:justify-evenly w-[100%] lg:pt-10 lg:pb-10  lg:dark:text-white'>
                    <li className='flex flex-col'>
                        <p className='lg:flex hidden lg:visible lg:mb-3 lg:items-center lg:font-bold lg:w-full lg:text-white font-bold'>Para empresas</p>
                        <ul className='flex flex-col mb-5'>
                            <li><button onClick={() => navigate('/contact')}>Seja nosso parceiro</button></li>
                            <li><button onClick={() => navigate('/contact')}>Seja nosso cliente</button></li>
                            <li><button onClick={() => navigate('/contact')}>Seja um credenciado</button></li>
                            <li><button onClick={() => navigate('/contact')}>Seja um correspondente</button></li>
                            <li><button onClick={() => navigate('/contact')}>Seja um investidor</button></li>
                        </ul>
                        <p className='lg:flex hidden lg:mb-3 lg:visible lg:items-center lg:font-bold lg:w-full lg:text-white font-bold'>Para colaboradores</p>
                        <ul className='flex flex-col'>
                            <li><button onClick={() => navigate('/contact')}>Recomende sua empresa</button></li>
                            <li><a href="https://play.google.com/store/search?q=sercred&c=apps&hl=pt_BR" className="flex lg:mb-3">Baixe nosso app</a></li>
                        </ul>
                    </li>
                    <li className='flex flex-col'>
                        <p className='lg:flex hidden lg:mb-3 lg:visible lg:items-center lg:font-bold lg:w-full lg:text-white font-bold'>Soluções</p>
                        <ul className='flex flex-col'>
                            <li><button onClick={() => handleNavigate('/solutions', 'inicio')}>Cartão Convênio</button></li>
                            <li><button onClick={() => handleNavigate('/solutions', 'Saque Rotativo')}>Saque Rotativo</button></li>
                            <li><button onClick={() => handleNavigate('/solutions', 'Saque Parcelado')}>Antecipação Salarial</button></li>
                            <li><button onClick={() => handleNavigate('/solutions', 'Empréstimo Consignado')}>Empréstimo Consignado</button></li>
                            <li><button onClick={() => handleNavigate('/parcelex', 'inicio')}>PIX Parcelado</button></li>
                            <li><button onClick={() => handleNavigate('/storeCard', 'inicio')}>Cartão Loja</button></li>
                        </ul>
                    </li>
                    <li className='flex flex-col'>
                        <p className='lg:flex hidden lg:mb-3 lg:visible lg:items-center lg:font-bold lg:w-full lg:text-white font-bold'>Acesse já</p>
                        <ul className='flex flex-col'>
                            <li><button onClick={() => navigate('/login')}>Plataforma do Parceiro</button></li>
                            <li><button onClick={() => navigate('/login')}>Plataforma do Cliente</button></li>
                            <li><button onClick={() => navigate('/login')}>Plataforma do Credenciado</button></li>
                            <li><button onClick={() => navigate('/login')}>Plataforma do RH</button></li>
                            <li><button onClick={() => navigate('/login')}>Plataforma do Financeiro</button></li>
                            <li><button onClick={() => navigate('/login')}>Plataforma do Correspondente</button></li>
                            <li><button onClick={() => navigate('/login')}>App web do Colaborador</button></li>
                            <li><a href="https://centrax-v3.parcelex.com.br/auth/login" className="flex">Parcelex</a></li>
                            <li><a href="https://siga.sigacred.com.br/?op=eJwzNDAwBgAB6wDF&or=eJxLzslMzStJBQALfALl">Cartão Loja</a></li>
                        </ul>
                    </li>
                    <li className='flex flex-col'>
                        <p className='lg:flex hidden lg:mb-3 lg:visible lg:items-center lg:font-bold lg:w-full lg:text-white font-bold'>Institucional</p>
                        <ul className='flex flex-col mb-5'>
                            <li><button onClick={() => navigate('/about')}>Quem somos</button></li>
                            <li><button onClick={() => navigate('/solutions')}>Nossas soluções</button></li>
                        </ul>
                        <p className='lg:flex hidden lg:mb-3 lg:visible lg:items-center lg:font-bold lg:w-full lg:text-white font-bold'>Atendimento</p>
                        <ul className='flex flex-col'>
                            <li><button onClick={() => navigate('/contact')}>Suporte</button></li>
                            <li><button onClick={() => navigate('/contact')}>Canais de atendimento</button></li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div className="flex bg-sercredDarkBlue dark:bg-sercredSecondBlueDark flex-col w-[100%] h-[50%] p-6 md:items-center">
                <div className='flex justify-center mb-8'>
                    <img src="logo branco.svg" alt="logo" className='w-[40%] md:w-[20%]' />
                </div>
                <div className='flex w-[100%] h-[15%] justify-evenly mb-8 md:w-[20%]'>
                    <div>
                        <a target='_blank' href="https://www.instagram.com/sercredconvenio?igsh=ZWloMHRwaXFqcTB2"><img src="instagram 7.svg" alt="" /></a>
                    </div>
                    <div>
                        <a target='_blank' href="https://www.linkedin.com/company/sercredconvenio/"><img src="linkedin 2.svg" alt="" /></a>
                    </div>
                    <div>
                        <a target='_blank' href="https://web.facebook.com/profile.php?id=61570547095625"><img src="facebook 7.svg" alt="" /></a>
                    </div>
                </div>
                <div className=' flex flex-col text-center justify-center items-center'>
                    <p className='mb-3 text-sm lg:w-[80%]'>A Sercred é uma plataforma digital que atua como Correspondente Bancário e Convênios consignados para desconto em folha. Como Correspondente Bancário, seguimos as diretrizes da Resolução CMN nº 4.935 do Banco Central do Brasil. Somos correspondentes bancários das seguintes instituições: PARCELEX (CNPJ 18.282.093/0001-50), DM CARD (CNPJ 32.997.490/0001-39) e BANCO VONTORANTIN (CNPJ 03.881.423/0001-56) nos termos da Resolução CMN nº 4.935, de 29 de Julho de 2021.</p>
                    <p className='mb-3 text-sm'>Sercred Paytech Ltda. - CNPJ 55.738691/0001-17, São José dos Campos, SP</p>
                    <button
                        onClick={toggleTheme}
                        className="p-2 text-white rounded"
                    >
                        {isDarkMode ? "Modo Claro" : "Modo Escuro"}
                    </button>
                    <div className='mb-3 text-sm flex justify-evenly w-[100%] underline lg:w-[35%]'>
                        <button onClick={() => handleNavigate('/terms', 'inicio')}>Politica de privacidade</button>
                        <button onClick={() => handleNavigate('/terms', 'inicio')}>Termos de uso</button>
                        <button onClick={() => navigate('/contact')}>Ajuda</button>
                    </div>
                    <p className='text-xs'>Copyright &copy; 2025. All Rights Reserved by Sercred</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;