import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from "../contexts/ThemeContext";

const Header = () => {
    const { isDarkMode, toggleTheme } = useTheme();
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [openDropdown, setOpenDropdown] = useState<number | null>(null);

    const menuRef = useRef<HTMLDivElement>(null);

    // Função para alternar o menu
    const toggleMenu = () => {
        setIsMenuOpen((prevState) => !prevState); // Alterna o estado do menu
    };

    // Função para alternar o estado do dropdown
    const toggleDropdown = (index: number) => {
        setOpenDropdown(openDropdown === index ? null : index);
    };

    // Fecha o menu/dropdown ao clicar fora
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target as Node)
            ) {
                setIsMenuOpen(false); // Fecha o menu
                setOpenDropdown(null); // Fecha qualquer dropdown aberto
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <header className="dark:bg-sercredSecondBlueDark dark:text-white flex items-center justify-between p-4 lg:w-[100%] lg:h-10%] lg:justify-evenly ">
            <div className='sm:hidden md:hidden'>
                {openDropdown === 0 && (
                    <div className="lg:fixed lg:inset-0 lg:mt-[8.5%] lg:bg-sercredDarkGrey lg:bg-opacity-50 lg:z-40 sm:hidden"></div>
                )}
                {openDropdown === 1 && (
                    <div className="lg:fixed lg:inset-0 lg:mt-[8.5%] lg:bg-sercredDarkGrey lg:bg-opacity-50 lg:z-40 sm:hidden"></div>
                )}
            </div>
            {/* Logo */}
            <div className="text-white flex justify-start text-xl lg:w-[20%] lg:justify-center lg:flex">
                {isDarkMode ? <button className="" onClick={() => navigate('/')}><img src="/Component 121.svg" alt="Logo" className="h-10" /></button> : <button className="" onClick={() => navigate('/')}><img src="/Component 118.svg" alt="Logo" className="h-10" /></button>}
            </div>

            {/* Menu Hamburger (visível apenas em mobile) */}
            <div className="lg:hidden dark:bg-sercredSecondBlueDark dark:text-white">
                {isMenuOpen ? (
                    // Ícone "X" quando o menu estiver aberto
                    <button onClick={toggleMenu} className="text-white">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="#000000"
                            className="h-6 w-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                ) : (
                    // Ícone Hamburger quando o menu estiver fechado
                    <button onClick={toggleMenu} className="text-white">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="#000000"
                            className="h-6 w-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                )}
            </div>

            {/* Menu Lateral (visível quando aberto em mobile) */}
            <div
                className={`fixed top-16 left-0 bg-white dark:bg-sercredSecondBlueDark w-full md:w-[50%] h-[100%] z-50 transform transition-transform ease-in-out duration-300 ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'
                    } lg:static lg:flex lg:w-[100%] lg:h-[10%] lg:translate-x-0 lg:items-center`}
            >
                <div className="dark:bg-sercredSecondBlueDark dark:text-white flex flex-col items-start p-6 space-y-8 text-sercredDarkBlue lg:w-[100%]">
                    <ul className="space-y-4 lg:flex lg:w-[100%] lg:items-center lg:justify-evenly dark:bg-sercredSecondBlueDark dark:text-white ">
                        <li className='lg:flex lg:items-center'>
                            <button className="flex lg:mt-4 items-center font-bold w-full text-left" onClick={() => navigate('/')}>Início</button>
                        </li>
                        <li className='lg:flex lg:items-center'>
                            <button
                                className="flex items-center font-bold w-full text-left"
                                onClick={() => navigate('/solutions')}
                            >
                                Soluções
                            </button>
                        </li>
                        <li className='lg:flex lg:items-center font-bold'><button onClick={() => navigate('/about')}>Sobre nós</button></li>
                        <li className='lg:flex lg:items-center font-bold'><button onClick={() => navigate('/parcelex')}>Parcelex</button></li>
                        <li className='lg:flex lg:items-center font-bold'><button onClick={() => navigate('/storeCard')}>Cartão loja</button></li>
                        <li className='lg:flex lg:items-center font-bold'><button onClick={() => navigate('/contact')}>Entre em contato</button></li>
                        <li className='lg:hidden dark:bg-sercredSecondBlueDark dark:text-white '>
                            <button
                                className="flex dark:bg-sercredSecondBlueDark dark:text-white items-center font-bold w-full text-left lg:border-[1px] lg:rounded-3xl lg:border-sercredDarkBlue lg:pt-2 lg:pb-2 lg:pl-3 lg:pr-3"
                                onClick={() => toggleDropdown(1)}
                            >
                                Entrar
                                <span className={`ml-2 transform ${openDropdown === 1 ? 'rotate-180' : ''}`}>
                                    <img src="Component 200.svg" alt="image-1" className='rotate-90 w-2' />
                                </span>
                            </button>
                            {openDropdown === 1 && (
                                <ul className="ml-4 space-y-2 mt-2">
                                    <li className='mb-2'><button onClick={() => window.open('https://sercred.com/login', '_blank')}>Sou Parceiro</button></li>
                                    <li className='mb-2'><button onClick={() => window.open('/login', '_blank')}>Sou Cliente (RH/Financeiro)</button></li>
                                    <li className='mb-2'><button onClick={() => window.open('/login', '_blank')}>Sou Colaborador</button></li>
                                    <li className='mb-2'><button onClick={() => window.open('/login', '_blank')}>Sou Correspondente</button></li>
                                    <li className='mb-2'><button onClick={() => window.open('/login', '_blank')}>Rede Credenciada</button></li>
                                    <li><a href="https://centrax-v3.parcelex.com.br/auth/login" className="hover:text-gray-300">Parcelex</a></li>
                                    <li><a href="https://siga.sigacred.com.br/?op=eJwzNDAwBgAB6wDF&or=eJxLzslMzStJBQALfALl" className="hover:text-gray-300">Cartão Loja</a></li>
                                </ul>
                            )}
                            <li className='lg:flex lg:items-center mt-5'><button
                                onClick={toggleTheme}
                                className="p-2 rounded text-sercredDarkBlue dark:text-sercredLightGrey hover:bg-sercredLightGrey dark:hover:bg-sercredDarkGrey"
                            >
                                {isDarkMode ? (
                                    // Ícone de Sol para Modo Claro
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6 hover:bg-none"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M12 3v1.5M12 19.5V21M4.22 4.22l1.06 1.06M18.72 18.72l1.06 1.06M3 12h1.5m16.5 0H21M4.22 19.78l1.06-1.06M18.72 5.28l1.06-1.06M12 7.5a4.5 4.5 0 100 9 4.5 4.5 0 000-9z"
                                        />
                                    </svg>
                                ) : (
                                    // Ícone de Lua para Modo Escuro
                                    <svg width="20" height="20" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3347 0.150146C12.7993 1.20337 7 7.67456 7 15.5C7 24.0603 13.9397 31 22.5 31C27.2795 31 31.5542 28.8364 34.3975 25.4353C31.5674 31.6665 25.2898 36 18 36C8.05884 36 0 27.9412 0 18C0 8.05884 8.05884 0 18 0C18.7913 0 19.5706 0.0512695 20.3347 0.150146Z" fill="#08427B" />
                                    </svg>

                                )}
                            </button></li>
                        </li>
                    </ul>
                </div>
                <div className='dark:bg-sercredSecondBlueDark dark:text-white hidden lg:flex lg:w-[20%] lg:justify-center items-center text-sercredDarkBlue'>
                    <button
                        className="flex dark:bg-sercredSecondBlueDark dark:text-white items-center font-bold w-[55%] justify-center text-center lg:border-[1px] lg:rounded-3xl lg:border-sercredDarkBlue lg:pt-2 lg:pb-2 lg:pl-3 lg:pr-3"
                        onClick={() => toggleDropdown(1)}
                    >
                        Entrar
                        <span className={`ml-2 dark:bg-sercredSecondBlueDark dark:text-white transform ${openDropdown === 1 ? 'rotate-180' : ''}`}>
                            <img src="Component 200.svg" alt="image-1" className='rotate-90 w-2 dark:bg-sercredSecondBlueDark dark:text-white ' />
                        </span>
                    </button>
                    <li className='lg:flex lg:items-center ml-5'><button
                        onClick={toggleTheme}
                        className="p-2 rounded text-sercredDarkBlue dark:text-sercredLightGrey hover:bg-sercredLightGrey dark:hover:bg-sercredDarkGrey"
                    >
                        {isDarkMode ? (
                            // Ícone de Sol para Modo Claro
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 hover:bg-none"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 3v1.5M12 19.5V21M4.22 4.22l1.06 1.06M18.72 18.72l1.06 1.06M3 12h1.5m16.5 0H21M4.22 19.78l1.06-1.06M18.72 5.28l1.06-1.06M12 7.5a4.5 4.5 0 100 9 4.5 4.5 0 000-9z"
                                />
                            </svg>
                        ) : (
                            // Ícone de Lua para Modo Escuro
                            <svg width="20" height="20" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3347 0.150146C12.7993 1.20337 7 7.67456 7 15.5C7 24.0603 13.9397 31 22.5 31C27.2795 31 31.5542 28.8364 34.3975 25.4353C31.5674 31.6665 25.2898 36 18 36C8.05884 36 0 27.9412 0 18C0 8.05884 8.05884 0 18 0C18.7913 0 19.5706 0.0512695 20.3347 0.150146Z" fill="#08427B" />
                            </svg>

                        )}
                    </button></li>
                    {openDropdown === 1 && (
                        <ul
                            className={`dark:bg-sercredDarkBlueDark dark:text-white lg:right-0 mt-2 lg:mt-0 lg:absolute lg:top-full lg:bg-white lg:shadow-lg lg:w-[20%] lg:p-4 ${openDropdown === 1 ? 'block' : 'hidden'
                                }`}
                        >
                            <li className='mb-2'><button onClick={() => window.open('https://sercred.com/login', '_blank')}>Sou Parceiro</button></li>
                            <li className='mb-2'><button onClick={() => window.open('/login', '_blank')}>Sou Cliente (RH/Financeiro)</button></li>
                            <li className='mb-2'><button onClick={() => window.open('/login', '_blank')}>Sou Colaborador</button></li>
                            <li className='mb-2'><button onClick={() => window.open('/login', '_blank')}>Sou Correspondente</button></li>
                            <li className='mb-2'><button onClick={() => window.open('/login', '_blank')}>Rede Credenciada</button></li>
                            <li className='mb-2'><a href="https://centrax-v3.parcelex.com.br/auth/login" target='_blank' className="hover:text-gray-300">Parcelex</a></li>
                            <li><a href="https://siga.sigacred.com.br/?op=eJwzNDAwBgAB6wDF&or=eJxLzslMzStJBQALfALl" target='_blank' className="hover:text-gray-300">Cartão Loja</a></li>
                        </ul>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;