export const servicesData = {
    services: [
        {
            serviceId: 1,
            image: "saque_rotativo.jpeg",
            title: "Saque Rotativo",
            description: "O saque transforma o limite do cartão convênio em saldo direto na conta bancária do seu colaborador, descontado direto na folha de pagamento posteriormente...",
        },
        {
            serviceId: 2,
            image: "family4.jpeg",
            title: "Saque Parcelado",
            description: "O saque parcelado, também conhecido como antecipação salarial, funciona quase como o saque rotativo, mas permite que o colaborador solicite um...",
        },
        {
            serviceId: 3,
            image: "emprestimo_consignado.jpeg",
            title: "Empréstimo Consignado",
            description: "O empréstimo consignado permite que o colaborador simule e solicite um empréstimo com desconto em folha e taxas reduzidas para pagamentos com prazos estendidos...",
        },
        {
            serviceId: 3,
            image: "pix_parcelado.jpeg",
            title: "PIX Parcelado",
            description: "Ofereça crédito e pagamentos flexíveis para seus clientes em até 24X no PIX ou Boleto sem precisar assumir risco nenhum...",
        },
        {
            serviceId: 3,
            image: "operaria.jpeg",
            title: "Convênio Empresarial",
            description: "Ofereça um cartão benefícios para seus colaboradores aproveitarem descontos e acesso a uma rede de lojas, mercados, farmácias...",
        },
        {
            serviceId: 3,
            image: "cozinheiro.jpeg",
            title: "Cartão Loja",
            description: "Um cartão de crédito exclusivo com a sua marca para seus clientes poderem consumir mais no seu estabelecimento...",
        },
    ],
};
