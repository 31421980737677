import React, { useEffect, useState } from 'react';
import { useTheme } from "../contexts/ThemeContext";

export default function Login() {

    const { isDarkMode, toggleTheme } = useTheme();

    const [inputEmail, setInputEmail] = useState('');
    const [inputPassword, setInputPassword] = useState('');
    const [inputValue, setInputValue] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [authError, setAuthError] = useState<string | null>(null);


    const handleEmailChange = (event: any) => {
        setInputEmail(event.target.value);
        updateInputValue(event.target.value, inputPassword);
    };

    const handlePasswordChange = (event: any) => {
        setInputPassword(event.target.value);
        updateInputValue(inputEmail, event.target.value);
    };

    const updateInputValue = (inputEmail: any, inputPassword: any) => {
        setInputValue(inputEmail && inputPassword);
    };

    const validateEmail = (inputEmail: any) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(inputEmail);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (!inputEmail || !inputPassword) {
            setEmailError('Por favor, preencha todos os campos.');
            return;
        }
        if (!validateEmail(inputEmail)) {
            setEmailError('Por favor, insira um endereço de e-mail válido.');
            return;
        }

        // Simular erro de autenticação
        setAuthError("Login ou senha incorretos!");
    };

    return (
        <main className="flex w-[100%] h-screen flex-col items-center justify-center bg-white dark:bg-sercredSecondBlueDark dark:text-white">
            <div className="lg:mt-20 dark:bg-sercredDarkBlueDark dark:text-white lg:h-3/4 w-[90%] lg:w-2/6 flex flex-col justify-center p-10 lg:pl-14 lg:pr-14" style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
                <div className='flex justify-between'>
                    <div>
                        <div className="mb-5">
                            {isDarkMode ? <button className=""><img src="/Component 121.svg" alt="Logo" className="h-10" /></button> : <button className="" ><img src="/Component 118.svg" alt="Logo" className="h-10" /></button>}
                        </div>
                    </div>
                    <div>
                        <li className='lg:flex lg:items-center lg:mb-5'><button
                            onClick={toggleTheme}
                            className="p-2 rounded text-sercredDarkBlue dark:text-sercredLightGrey hover:bg-sercredLightGrey dark:hover:bg-sercredDarkGrey"
                        >
                            {isDarkMode ? (
                                // Ícone de Sol para Modo Claro
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6 hover:bg-none"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 3v1.5M12 19.5V21M4.22 4.22l1.06 1.06M18.72 18.72l1.06 1.06M3 12h1.5m16.5 0H21M4.22 19.78l1.06-1.06M18.72 5.28l1.06-1.06M12 7.5a4.5 4.5 0 100 9 4.5 4.5 0 000-9z"
                                    />
                                </svg>
                            ) : (
                                // Ícone de Lua para Modo Escuro
                                <svg width="20" height="20" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3347 0.150146C12.7993 1.20337 7 7.67456 7 15.5C7 24.0603 13.9397 31 22.5 31C27.2795 31 31.5542 28.8364 34.3975 25.4353C31.5674 31.6665 25.2898 36 18 36C8.05884 36 0 27.9412 0 18C0 8.05884 8.05884 0 18 0C18.7913 0 19.5706 0.0512695 20.3347 0.150146Z" fill="#08427B" />
                                </svg>

                            )}
                        </button></li>
                    </div>
                </div>
                <div className="text-2xl mb-5">
                    <h1>Faça seu Login</h1>
                </div>
                <div>
                    <div>
                        <div className="border border-solid border-sercredDarkGrey w-full lg:h-14 flex flex-col justify-center p-3 mb-5" style={{ borderRadius: '8px' }}>
                            <input type="email" value={inputEmail} onChange={handleEmailChange} placeholder="Digite seu e-mail" className="text-sm outline-none dark:bg-sercredDarkBlueDark" />
                        </div>
                    </div>
                    <div>
                        <div className="border border-solid border-sercredDarkGrey w-full lg:h-14 flex flex-col justify-center p-3 mb-5" style={{ borderRadius: '8px' }}>
                            <input type="password" value={inputPassword} onChange={handlePasswordChange} placeholder="Digite sua senha" className="text-sm outline-none dark:bg-sercredDarkBlueDark" />
                        </div>
                    </div>
                    {authError && (
                        <p className="text-sercredRed text-sm text-center mb-4">{authError}</p>
                    )}
                    <div >
                        <button onClick={handleSubmit} className={`px-4 py-2 rounded-lg ${inputValue ? 'bg-sercredDarkBlue text-white mb-5' : 'bg-sercredLightGrey text-white cursor-not-allowed mb-5'}`}>Continuar</button>
                    </div>
                </div>
                <div>
                    <p className="text-sm">Esqueceu sua senha? <a className="text-sercredDarkBlue font-semibold" href="#">Clique aqui</a></p>
                </div>
            </div>
        </main>
    );
}
