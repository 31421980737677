import React, { useState } from "react";
import { faqData } from "../json/faqData";

interface FaqItem {
    id: number;
    question: string;
    answer: string;
}

interface faqDataProps {
    [category: string]: FaqItem[];
}

const FAQ: React.FC<faqDataProps> = ({ category, id, string, answer}) => {
    const [activeCategory, setActiveCategory] = useState("Parcerias");
    const [openQuestion, setOpenQuestion] = useState(null);

    const handleCategoryChange = (category: any) => {
        setActiveCategory(category);
        setOpenQuestion(null); // Fecha qualquer pergunta aberta ao trocar de categoria
    };

    const handleToggleQuestion = (id: any) => {
        setOpenQuestion(openQuestion === id ? null : id); // Alterna a pergunta aberta
    };

    return (
        <div className="flex flex-col items-center p-8 lg:pt-20 lg:pb-20 dark:text-white">
            {/* Título */}
            <h1 className="text-4xl text-sercredDarkBlue dark:text-white mb-6">Dúvidas Frequentes por produto</h1>

            {/* Botões das Categorias */}
            <div className="flex space-x-4 mb-4 text-sercredDarkBlue lg:text-xl flex-wrap justify-center dark:text-white">
                {Object.keys(faqData).map((category) => (
                    <button
                        key={category}
                        onClick={() => handleCategoryChange(category)}
                        className={`py-2 px-4 font-medium ${activeCategory === category
                                ? "text-blue-600 border-b-2 border-blue-600"
                                : "text-gray-600 hover:text-blue-600"
                            }`}
                    >
                        {category}
                    </button>
                ))}
            </div>

            {/* Perguntas */}
            <div className="w-[90%] lg:w-[70%] text-sercredDarkBlue text-xl dark:text-white">
                {faqData[activeCategory].map((item) => (
                    <div key={item.id} className="border-b border-sercredDarkBlue py-4">
                        <button
                            onClick={() => handleToggleQuestion(item.id)}
                            className="flex justify-between w-full text-left items-center"
                        >
                            <span className="font-medium">{item.question}</span>
                            <span
                                className={`transform transition-transform ${openQuestion === item.id ? "rotate-90" : ""
                                    }`}
                            >
                                &gt;
                            </span>
                        </button>
                        {openQuestion === item.id && (
                            <p className="mt-3 text-gray-600">{item.answer}</p>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;