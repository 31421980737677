import React, { useState, useRef } from 'react';
import Footer from './footer';
import Header from './header';

const Terms = () => {

    return (
        <div className="flex flex-col w-[100%] h-[100%] dark:bg-sercredSecondBlueDark dark:text-white">
            <div id='inicio'>
                <Header />
            </div>
            <div className='flex flex-col lg:mb-10  text-[#304263] dark:bg-sercredSecondBlueDark dark:text-white'>
                <div className='flex flex-col items-center p-6 lg:items-start lg:pl-40'>
                    <h1 className='text-2xl mb-5 lg:text-5xl lg:mb-8'>Politica de Privacidade</h1>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start'>Leia este Aviso de Privacidade com atenção. Ao acessar nosso website e/ou utilizar nosso aplicativo, você automaticamente declara estar plenamente ciente dos termos aqui dispostos. Versão: 1 | Última atualização: 12 de dezembro de 2024</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>O presente Aviso de Privacidade tem a finalidade de esclarecer e informar aos titulares que acessam o website ou utilizam o aplicativo da Sercred, como seus dados pessoais são tratados, especialmente no que se refere às operações de coleta, uso, armazenamento e compartilhamento.</p>
                </div>
                <div className='flex flex-col items-center p-6 lg:items-start lg:pl-40'>
                    <h1 className='text-2xl mb-5 lg:text-5xl lg:mb-8'>Quem Somos</h1>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start'>A Sercred é uma plataforma digital de convênios empresariais consignados e que atua também como Correspondente Bancário, facilitando o processo de contratação de empréstimos e outros serviços financeiros.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Este Aviso de Privacidade se aplica a todos os serviços e operações de processamento de dados pessoais da SERCRED FINTECH LTDA. ('SERCRED'), inscrita no CNPJ n° 55.738691/0001-17, com sede em São José dos Campos, estado de São Paulo</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Ao acessar ou utilizar o site: https://www.sercred.com ou os aplicativos da Sercred na Google Play Store e na Apple App Store (ambos referidos como “Aplicativo”), o usuário (“Usuário”) declara ter lido, entendido e concordado com o Aviso de Privacidade.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>A Sercred compromete-se a respeitar a privacidade e a resguardar a segurança dos titulares de dados pessoais, garantindo a legalidade e a transparência das atividades de tratamento. Assim, apresenta este Aviso de Privacidade (“Aviso”) para descrever, de forma clara e acessível, como os dados pessoais serão coletados, armazenados, usados e compartilhados, em conformidade com as legislações aplicáveis no Brasil, em especial, a Lei Federal nº. 13.709/2018 (“Lei Geral de Proteção de Dados Pessoais” ou “LGPD”).</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Esse documento é o principal em relação a privacidade e tratamento de dados pessoais, mas todos os demais documentos que regem a relação entre o Usuário e a Sercred são parte integrante e inseparável deste Aviso, incluindo, mas não se limitando aos Termos e Condições de Uso e a Política de Segurança da Informação.</p>
                </div>

                <div className='flex flex-col items-center p-6 lg:items-start lg:pl-40'>
                    <h1 className='text-2xl mb-5 lg:text-5xl lg:mb-8'>Definições</h1>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start'>Este Aviso de Privacidade busca fortalecer o compromisso da Sercred com seus valores corporativos, que incluem o bom relacionamento com os Usuários e a transparência dos seus processos, além de explicar como realiza o tratamento dos dados pessoais dos Usuários, quais são os direitos dos titulares e como poderão ser exercidos, em linha com as disposições da LGPD e demais leis em vigor. A Sercred Soluções Fintech Ltda. é a controladora dos dados pessoais dos Usuários tratados no âmbito deste Aviso de Privacidade.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>A seguir, apresentam-se alguns conceitos importantes para que você, titular de dados pessoais, possa interpretar facilmente os termos deste Aviso de Privacidade:</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>1 - “Usuário”: toda pessoa que acessa e/ou utiliza o website, o Aplicativo e/ou os nossos Serviços, de forma on-line ou off-line;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>2 - “Anonimização”: utilização de meios técnicos razoáveis e disponíveis no momento do tratamento, por meio dos quais um dado pessoal perde a possibilidade de associação, direta ou indireta, a um indivíduo;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>3 - “Banco de dados”: conjunto estruturado de dados pessoais, estabelecido em um ou em vários locais, em suporte eletrônico ou físico;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>4 - “Compartilhamento de dados pessoais”: comunicação, difusão, transferência internacional, interconexão de dados pessoais ou tratamento compartilhado de bancos de dados pessoais por órgãos e entidades públicas no cumprimento de suas competências legais, ou entre esses e entes privados, reciprocamente, com autorização específica, para um ou mais modalidades de tratamento permitidas por esses entes públicos, ou entre entes privados;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>5 - “Consentimento": autorização livre, informada e inequívoca (sem deixar dúvidas) pela qual o Usuário concorda com o tratamento de seus dados pessoais para uma finalidade previamente estipulada.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>6 - Controlador”: pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>7 - “Dados pessoais": qualquer informação relacionada à pessoa natural que a identifique, ou que usada em combinação com outras informações tratadas, identifiquem um indivíduo. Ainda, qualquer informação por meio da qual a identificação ou informação de contato de uma pessoa natural seja possível; a) “Dados pessoais sensíveis”: dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou à organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>8 - “Dado anonimizado”: dado relativo ao titular que não possa ser identificado, considerando a utilização de meios técnicos razoáveis e disponíveis da ocasião do tratamento;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>9 - “Eliminação”: exclusão de dado ou de conjunto de dados armazenados em banco de dados, independentemente do procedimento empregado;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>10 - “Endereço IP”: um endereço IP é um número atribuído ao computador ou rede quando acessado à Internet. Geralmente está associado ao local de entrada na Internet, como provedor de serviços de Internet;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>11 - “Finalidade”: o que a Sercred objetiva a partir do tratamento de dados pessoais;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>12 - “LGPD": significa “Lei Geral de Proteção de Dados Pessoais”. Trata-se da Lei Federal n.º 13.709/2018;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>13 - “Logs”: são registros de acesso às aplicações de Internet que reúnem informações referentes à data e hora de uso da aplicação considerando um determinado endereço IP.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>14 - “Necessidade”: razão pela qual se justifica o tratamento de dados pessoais para que seja atingida a finalidade pretendida;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>15 - “Serviços”: refere-se a todo e qualquer serviço prestado pela Sercred;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>16 - “Website”: refere-se a todo o conteúdo e as funcionalidades disponíveis no endereço: https://www.sercred.com/;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>17 - “Termos de Uso”: Os Termos e Condições Gerais de Uso do nosso site: https://www.sercred.com/terms</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>18 - “Titular de dados pessoais”: pessoa natural a quem se refere os dados pessoais objeto do tratamento;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>19 - “Tratamento de dados pessoais": considera-se tratamento de dado pessoal a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração de dados de pessoas físicas.</p>
                </div>
                <div className='flex flex-col items-center p-6 lg:items-start lg:pl-40'>
                    <h1 className='text-2xl mb-5 lg:text-5xl lg:mb-8'>A quem se aplica?</h1>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start'>Os termos desse Aviso se aplicam a todos aqueles que acessarem as páginas e domínios da Sercred, sejam eles clientes ou não. De forma exemplificativa, esse Aviso se aplica:</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>1- aos Usuários que interagem com o website e ou o Aplicativo; </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>2 - a visitantes;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>3 - a pessoas naturais que acessem o website e ou o Aplicativo; </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>4 - a pessoas jurídicas, na pessoa física do seu representante; </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>5 - bem como, àqueles que de alguma forma tenham seus dados pessoais tratados pela Sercred. </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Em conjunto, aqui denominados como “Usuários”.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Importante mencionar que, este Aviso de Privacidade não se aplica às informações pessoais anonimizadas ou em formato agregado (versões anônimas de informações do Usuário, dados agregados ou dados que não identificam um indivíduo em particular.</p>
                </div>
                <div className='flex flex-col items-center p-6 lg:items-start lg:pl-40'>
                    <h1 className='text-2xl mb-5 lg:text-5xl lg:mb-8'>Contatos</h1>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mb-5'>Se o titular acreditar que seus dados pessoais foram tratados de maneira incompatível com este Aviso ou com as suas escolhas enquanto titular de dados pessoais, ou, ainda, se tiver dúvidas, comentários ou sugestões relacionadas a este Aviso e a forma como a Sercred trata seus dados pessoais, entre em contato através dos seguintes canais:</p>
                    <p className='text-[#001f40] dark:bg-sercredSecondBlueDark dark:text-white font-bold flex'><span className='dark:bg-sercredSecondBlueDark dark:text-white mr-1 font-normal text-[#001f40]'>Telefone Suporte: </span>(12) 99703-2407 <a
                            href="https://wa.me/5512997032407"
                            target="_blank"
                            rel="noopener noreferrer"
                            className=""
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" className="w-4 h-4  -rotate-45">
                                <path d="M10.828 5.172a.5.5 0 0 1 .707-.707l3 3a.5.5 0 0 1 0 .707l-3 3a.5.5 0 1 1-.707-.707L12.793 8.5H5.5a.5.5 0 0 1 0-1h7.293l-1.965-1.965Z" />
                            </svg>
                        </a></p>
                        <p className='text-[#001f40] dark:bg-sercredSecondBlueDark dark:text-white font-bold flex'><span className='dark:bg-sercredSecondBlueDark dark:text-white mr-1 font-normal text-[#001f40]'>Telefone Comercial: </span>(12) 99770-5801 <a
                            href="https://wa.me/5512997705801"
                            target="_blank"
                            rel="noopener noreferrer"
                            className=""
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" className="w-4 h-4  -rotate-45">
                                <path d="M10.828 5.172a.5.5 0 0 1 .707-.707l3 3a.5.5 0 0 1 0 .707l-3 3a.5.5 0 1 1-.707-.707L12.793 8.5H5.5a.5.5 0 0 1 0-1h7.293l-1.965-1.965Z" />
                            </svg>
                        </a></p>
                    <p className='text-[#001f40] dark:bg-sercredSecondBlueDark dark:text-white font-bold flex'><span className='dark:bg-sercredSecondBlueDark dark:text-white mr-1 font-normal text-[#001f40]'>Email Suporte: </span>suporte@sercred.com <a
                            href="mailto:suporte@sercred.com"
                            className="ml-1"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" className="w-4 h-4  -rotate-45">
                                <path d="M10.828 5.172a.5.5 0 0 1 .707-.707l3 3a.5.5 0 0 1 0 .707l-3 3a.5.5 0 1 1-.707-.707L12.793 8.5H5.5a.5.5 0 0 1 0-1h7.293l-1.965-1.965Z" />
                            </svg>
                        </a></p>
                        <p className='text-[#001f40] dark:bg-sercredSecondBlueDark dark:text-white font-bold flex'><span className=' dark:bg-sercredSecondBlueDark dark:text-white mr-1 font-normal text-[#001f40]'>Email Comercial: </span>comercial@sercred.com <a
                            href="mailto:comerciale@sercred.com"
                            className="ml-1"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" className="w-4 h-4  -rotate-45">
                                <path d="M10.828 5.172a.5.5 0 0 1 .707-.707l3 3a.5.5 0 0 1 0 .707l-3 3a.5.5 0 1 1-.707-.707L12.793 8.5H5.5a.5.5 0 0 1 0-1h7.293l-1.965-1.965Z" />
                            </svg>
                        </a></p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>A Sercred possui um time de suporte, disponível em horário comercial para atender você.</p>
                </div>
                <div className='flex flex-col items-center p-6 lg:items-start lg:pl-40'>
                    <h1 className='text-2xl mb-5 lg:text-5xl lg:mb-8'>Dados Tratados</h1>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start'>O tratamento de dados pessoais é realizado conforme as diretrizes da Lei Geral de Proteção de Dados Pessoais em vigor e em consonância com os princípios éticos e melhores práticas exigidas pelo mercado. </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Ao acessar ou se cadastrar no website ou no Aplicativo, o titular poderá ter os seguintes dados pessoais tratados: </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>1 - Dados de cadastro de Usuário: nome completo, carteira de motorista, e-mail, endereço, estado civil, telefone de contato, CPF, RG, data de nascimento, país, sexo, idade, nacionalidade, estado civil, regime civil e filiação (nome da mãe e nome do pai), informações profissionais e escolaridade a depender do serviço ou produto contratado.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>2 - Dados financeiros: renda, despesas, débitos em haver, bens de sua propriedade, placa de veículo, dados bancários, outros dados do seu patrimônio, dados de crédito como o seu histórico e o seu score de crédito, dados de pagamento de seguros como a cobertura e os prêmios, dados de apólice e informações sobre seguro, sempre a depender do serviço ou produto contratado.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>3 - Dados de localização: IP do seu computador ou celular, data e hora, dados de geolocalização.  </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>4 - Dados de comportamento: locais que você mais acessa no website ou no Aplicativo, histórico de consumo dos produtos e serviços da Sercred, histórico de utilização do sistema.  </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>5 - Dados sensíveis: histórico de doenças, doenças presentes, se a pessoa é portadora de deficiência física ou não, dados de biometria facial ou digital. Esses dados são tratados a depender do serviço ou produto contratado.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Os dados pessoais podem ser fornecidos diretamente por você, Usuário, ou por terceiros, como outras empresas do Grupo Sercred, parceiros estratégicos como prestadores de serviço, clientes, bureaus de crédito, outras instituições do sistema financeiro, correspondentes bancários, empresas de seguro e órgãos públicos.  </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Os dados pessoais também podem ser coletados automaticamente por meio de cookies ou outras tecnologias similares. </p>
                </div>
                <div className='flex flex-col items-center p-6 lg:items-start lg:pl-40'>
                    <h1 className='text-2xl mb-5 lg:text-5xl lg:mb-8'>Cookies</h1>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start'>“Cookies” são pequenos arquivos instalados seu dispositivo que permitem a coleta de informações específicas, incluindo dados pessoais. Eles nos possibilitam diversas funções, como reconhecer as preferências do Usuário para adaptar o site à sua navegação e necessidades específicas.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>A Sercred utiliza Cookies para armazenar suas preferências, sempre com o objetivo de facilitar o uso e adequar a Plataforma aos interesses e necessidades dos Usuários, além de proporcionar uma melhor e eficiente experiência de acesso à Plataforma. Por meio de Cookies, a Sercred também poderá armazenar informações sobre as atividades do navegador, incluindo endereço IP e a página acessada. Esses registros de atividades (logs) serão utilizados apenas para fins estatísticos e de métricas dos serviços disponibilizados ou para cumprimento de obrigação legal.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Existem diferentes tipos de Cookies que a Sercred pode utilizar:</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>1 - Necessários: essenciais para o funcionamento adequado do site e para permitir a navegação e utilização das aplicações. São fundamentais, por exemplo, para equilibrar a carga do servidor do site, verificar sua funcionalidade ou acessar áreas seguras do site através de um login. Esses Cookies não identificam o Usuário e sem eles o site pode não operar corretamente;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>2 - Funcionais: armazenam informações sobre suas preferências, como, por exemplo, o seu idioma e sua região, permitindo uma melhor experiência de navegação no site;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>3 - Rastreamento: registram a sua atividade dentro dos nossos sites, como cliques em links e anúncios, coletando informações sobre as páginas acessadas para análise de dados e personalização dos conteúdos a serem apresentados a você.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>4 - Desempenho: analisam a forma que você interage com a Plataforma, recolhe informações, como áreas mais visitadas, tempo gasto no website, número de visitantes e páginas mais populares. Esses Cookies não identificam o Usuário e são utilizados para análise e estatística, agregando informações anonimamente.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Você tem a opção de aceitar ou recusar o uso de Cookies em seu dispositivo, utilizando as configurações de seu navegador de preferência. Todavia, caso não aceite alguns Cookies, certos serviços e funcionalidades da página da Sercred poderão não funcionar de maneira ideal.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>É possível encontrar mais explicações sobre como proceder clicando nos links abaixo. Ao acessar os respectivos sites, o Usuário estará sujeito aos Avisos de Privacidade de terceiros e deve se certificar de ler o Aviso de Privacidade específico de cada sítio eletrônico visitado.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Como remover Cookies no Internet Explorer: <a href="http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies">acessar</a></p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Como remover Cookies no Microsoft Edge: <a href="https://support.microsoft.com/es-ar/help/4027947/microsoft-edge-delete-cookies">acessar</a></p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Como remover Cookies no Firefox: <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">acessar</a></p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Como remover Cookies no Google Chrome: <a href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647">acessar</a></p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Como remover Cookies no Safari: <a href="https://www.apple.com/legal/privacy/es/cookies/">acessar</a></p>
                </div>
                <div className='flex flex-col items-center p-6 lg:items-start lg:pl-40'>
                    <h1 className='text-2xl mb-5 lg:text-5xl lg:mb-8'>Finalidades do tratamento de dados</h1>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start'>A Sercred realiza o tratamento de dados pessoais de acordo com as bases legais previstas na Lei Geral de Proteção de Dados Pessoais (LGPD). Listamos abaixo algumas situações em que a Sercred poderá tratar os dados pessoais dos Usuários:</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>1 - Realizar e manter o cadastro do Usuário atualizado no Website e no Aplicativo; </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>2 - Dar cumprimento e realizar as ações relacionadas ao contrato de prestação de serviço com o Usuário; </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>3 - Atividades de solicitação de cotações ou propostas por meio do Aplicativo; </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>4 - Atender aos Usuários e potenciais usuários tirando dúvidas, recebendo reclamações e demais solicitações no canal de atendimento ao cliente;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>5 - Envio de comunicação sobre os produtos e serviços contratados, necessários para o cumprimento do contrato; </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>6 - Envio de comunicações sobre novos produtos e serviços que sejam de interesse do Usuário;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>7 - Para realizar as avaliações necessárias para execução de contratos relativos aos serviços oferecidos, como, por exemplo, informações sobre a saúde do Usuário são necessárias para a contratação de determinados produtos, como de seguros, ou, para avaliação de outros sinistros relacionados; </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>8 - Para detectar e prevenir violações dos termos, regulamentos ou políticas da Sercred e/ou outras atividades prejudiciais ou ilegais;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>9 - Entender os Usuários e oferecer produtos mais adequados ao seu perfil; </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>10 - Avaliar os comportamentos de navegação e o perfil dos Usuários; </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>11 - Identificar, prevenir e gerenciar eventuais riscos de segurança física e cibernética;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>12 - Para detectar e prevenir fraudes e garantir a segurança no uso do Website e do Aplicativo, inclusive, com a utilização de biometria (facial ou digital dos Usuários) e com a coleta de dados de geolocalização dos Usuários para fins de identificação e autenticação de suas identidades; </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>13 - Para viabilizar as funções básicas do Website e do Aplicativo; </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>14 - Analisar o perfil, identificar, gerenciar e tratar potenciais riscos na oferta e contratação de produtos e/ou serviços por parte dos Usuários, como risco de crédito, riscos operacionais, riscos reputacionais, riscos de mercado, dentre outros; </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>15 - Para garantir o cumprimento de obrigações legais e regulatórias; </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>16 - Para o exercício regular de direitos da Sercred em processos judiciais, administrativos ou arbitrais; </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>17 - Para garantir o cumprimento de ordens judiciais, administrativas ou arbitrais por parte da Sercred; </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>18 - Aprimorar os produtos, processos e serviços da Sercred; </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>19 - Realizar pesquisas e campanhas, a fim de melhorar a experiência de uso dos Usuários no Website e no Aplicativo; </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>20 - Realizar campanhas de marketing e apresentação de novos produtos, com opção de opt-out pelo titular;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>21 - Analisar o desempenho, as tendências e medir a audiência do Website e do Aplicativo, verificar os hábitos de navegação do Usuário, a forma pela qual ele chegou nas plataformas (por exemplo, por meio de links de outros sites, buscadores ou diretamente), avaliar estatísticas relacionadas ao número de acessos e uso do Website e do Aplicativo, seus recursos e funcionalidades; </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>22 - Finalidades secundárias que não sejam conflitantes ou excessivas em relação às finalidades listadas neste Aviso, sempre em conformidade com a legislação brasileira e em observância aos direitos e liberdades individuais dos titulares de dados pessoais.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>A Sercred não solicita, coleta, processa, armazena ou compartilha, conscientemente, dados pessoais de crianças e adolescentes. Se descobrirmos a ocorrência de qualquer tipo de manuseio dos referidos dados, de forma não intencional, removeremos os dados pessoais daquela criança ou adolescente de nossos registros imediatamente. </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Entretanto, quando aplicável, a Sercred poderá coletar dados pessoais de crianças e adolescentes diretamente dos seus pais ou responsáveis legais, com consentimento explícito e de acordo com as regras da legislação vigente de proteção de dados pessoais.</p>
                </div>
                <div className='flex flex-col items-center p-6 lg:items-start lg:pl-40'>
                    <h1 className='text-2xl mb-5 lg:text-5xl lg:mb-8'>Compartilhamento de dados com terceiros</h1>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start'>A fim de cumprir as finalidades descritas neste Aviso, a Sercred poderá compartilhar seus dados pessoais com empresas do mesmo grupo econômico da Sercred e empresas parceiras, visando oferecimento de benefícios e novas oportunidades e/ou envio de comunicações e publicidade, aprimoramento da qualidade de serviço prestado e da experiência do Usuário na Plataforma, o que se dará de maneira justificada, sempre mantendo os mais altos padrões de segurança, buscando preservar ao máximo a sua privacidade.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Abaixo estão descritas algumas situações em que há a possibilidade do compartilhamento dos dados pessoais e as suas respectivas finalidades: </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>1 - Prestadores de serviço e fornecedores de tecnologia: a Sercred opera em parceria com outras organizações para viabilizar as suas atividades, como prestadoras de serviços de hospedagem de dados, de segurança patrimonial, entre outros. Objetivando minimizar os riscos para os titulares de Dados, os fornecedores são avaliados regularmente e se comprometem com obrigações contratuais de segurança da informação e proteção de dados pessoais;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>2 - Bureaus de crédito: para verificar a capacidade de pagamento do Usuário, a veracidade das informações prestadas, proteção ao crédito e prevenir fraudes; </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>3 - Autoridades Públicas. Para o cumprimento da legislação aplicável ou diante de requisição de autoridades públicas ou governamentais;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>4 -  Links de Terceiros. A plataforma pode incluir links para sites de terceiros, plug-ins ou aplicativos. Clicar nesses links ou habilitar tais conexões poderá permitir que terceiros coletem ou compartilhem dados pessoais dos titulares. A Sercred não é responsável pelo Aviso de Privacidade destes sites de terceiros;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>5 - Clientes ou Parceiros. Para envio de e-mail de oferecimento de benefícios e novas oportunidades, conteúdo mediante opção de opt-out do titular. </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Caso a Sercred passe por qualquer operação societária ou alienação de propriedade intelectual que seja parte de uma venda, fusão, alteração no controle ou qualquer evento preparatório para qualquer dessas operações, ela estará autorizada a compartilhar os dados pessoais dos Usuários com terceiros participantes do evento. Qualquer outra entidade que comprar a Sercred ou parte desta, terá o direito de continuar utilizando os dados pessoais se sub-rogando em todos os direitos incluídos neste documento.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>A Sercred poderá transferir dados pessoais para outros países nas hipóteses de fornecimento de serviços ou funcionalidades que se utilizam de infraestrutura tecnológica estabelecida fora do Brasil, como servidores de hospedagem e serviços em nuvem ou quando precisarmos envolver Parceiros localizados no exterior.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Nos casos de transferência internacional, a Sercred assegura que os países para quais os dados serão transferidos proporcionam nível de proteção dos dados pessoais semelhante ao previsto na legislação brasileira em vigor.</p>
                </div>
                <div className='flex flex-col items-center p-6 lg:items-start lg:pl-40'>
                    <h1 className='text-2xl mb-5 lg:text-5xl lg:mb-8'>Retenção e descarte de dados</h1>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start'>Os dados pessoais tratados pela Sercred serão armazenados durante o tempo de vigência do contrato. Findo este prazo, as informações do titular, incluindo dados pessoais de terceiros, serão armazenadas de acordo com as normas de prescrição do direito brasileiro, salvo se o tratamento tiver como base legal o consentimento do titular ou o legítimo interesse que justifique o armazenamento, bem como se a retenção estiver autorizada pela legislação vigente.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Após o prazo prescricional, os dados pessoais tratados pela Sercred serão eliminados, com exceção dos casos em que o tratamento for necessário em razão das hipóteses previstas nos incisos do art. 16, da LGPD, abaixo destacadas:</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>1 - Cumprimento de obrigação legal ou regulatória pelo controlador;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>2 - Estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>3 - Transferência a terceiro, desde que respeitados os requisitos de tratamento de dados pessoais dispostos nesta Lei; ou</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>4 - Uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados pessoais.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Em determinados casos, a Sercred possui obrigações legais que determinam a guarda dos dados pessoais por períodos específicos, a exemplo dos dados de endereço IP, hora e data de cada acesso ao website, que precisam ser retidos pelo menos por 6 (seis) meses, em observância ao artigo 15 do Marco Civil da Internet.</p>
                </div>
                <div className='flex flex-col items-center p-6 lg:items-start lg:pl-40'>
                    <h1 className='text-2xl mb-5 lg:text-5xl lg:mb-8'>Segurança dos Dados</h1>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start'>A responsabilidade da Sercred é cuidar dos dados pessoais e utilizá-los somente para as finalidades descritas neste Aviso. Para garantir a privacidade e a proteção dos dados pessoais dos Usuários, a Sercred adota medidas técnicas e administrativas adequadas e recursos tecnológicos para garantir a segurança de todos os dados pessoais tratados.  Entre as medidas de segurança implementadas estão controles de acesso a sistemas, política técnica de segurança da informação, política de backup, instalação de barreiras contra o acesso indevido às bases de dados (incluindo firewalls), entre outros controles de segurança da informação.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>A Sercred se esforça para proteger a privacidade dos dados pessoais dos seus Usuários, mas, infelizmente, por motivos de força maior, não podemos garantir total segurança. Entradas e usos não autorizados de terceiros com informações dos Usuários, falhas de hardware ou software que não estejam sob controle da empresa e outros fatores externos poderão comprometer a segurança dos dados pessoais. </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>A segurança das informações também deve vir do Usuário, por isso, a Sercred recomenda que seja utilizada uma senha que não contenha partes de seus dados pessoais (nome, data de aniversário etc.) e que contenha letras maiúsculas, números e caracteres especiais. É recomendado, ainda, que o Usuário não utilize a mesma senha para outros sites e/ou serviços e que troque sua senha de forma periódica. Além disso, é importante que o Usuário mantenha seus dados da Conta de Acesso as plataformas da Sercred (login e senha) sob total sigilo, não as divulgando a outros Usuários ou a terceiros.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>A Sercred nunca solicitará ao Usuário que informe sua senha fora da plataforma, por telefone, e-mail ou por qualquer outro meio de comunicação. Caso o Usuário suspeite que sua senha tenha sido exposta, a Sercred recomenda a imediata substituição da senha.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Caso o Usuário identifique ou tome conhecimento de qualquer fator que comprometa a segurança dos seus dados pessoais na sua relação com a Empresa, por favor, entre em contato com a Sercred por meio das informações de contato indicadas neste documento.</p>
                </div>
                <div className='flex flex-col items-center p-6 lg:items-start lg:pl-40'>
                    <h1 className='text-2xl mb-5 lg:text-5xl lg:mb-8'>Direito dos titulares dos dados</h1>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start'>Os titulares de dados pessoais têm o direito de solicitar à Sercred informações referentes ao tratamento de seus dados pessoais. Todas as solicitações apresentadas serão tratadas de forma gratuita, e serão submetidas a uma prévia avaliação da sua identidade e da viabilidade do atendimento, a fim de cumprir com eventuais obrigações legais que impeçam o completo atendimento das requisições dos titulares de direito.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>O titular de dados pessoais poderá exercer tais direitos mediante a solicitação através do nosso suporte pelos meios de comunicação disponibilizados </p>
                </div>
                <div className='flex flex-col items-center p-6 lg:items-start lg:pl-40'>
                    <h1 className='text-2xl mb-5 lg:text-5xl lg:mb-8'>Alterações</h1>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start'>A Sercred poderá modificar, a qualquer tempo, o presente “Aviso de Privacidade” para refletir eventuais alterações necessárias, visando o aprimoramento e à correspondência com os serviços prestados ou para atendimento de questões legais, administrativas ou de ordens judiciais. </p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>As versões atualizadas serão publicadas no website. A Sercred informará aos Usuários e clientes acerca das alterações que impliquem em alteração dos direitos e deveres dos Usuários, por quaisquer das seguintes formas: avisos por e-mail de Usuário cadastrado, mensagens na Plataforma, banners no website ou aplicativo, ou outro meio considerado adequado.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Caso os Usuários não concordem com o novo regulamento, deverão entrar em contato com a Sercred para informá-la e solicitar cancelamento de eventual cadastrou e/ou interrupção dos serviços prestados. Se não for o caso, os novos Termos e Condições de Uso e Aviso de Privacidade serão considerados como aceitos pelos Usuários.</p>
                    <p className='text-md text-center lg:text-xl lg:w-[85%] lg:text-start mt-5'>Recomendamos ainda que o titular verifique periodicamente este Aviso para se familiarizar com eventuais alterações.</p>
                </div>
            </div>
            <div className='flex w-[100%] h-[100%]'>
                <Footer />
            </div>
        </div>
    );
};

export default Terms;