import { useState } from "react";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home';
import Solutions from './components/solutions';
import About from "./components/about";
import Parcelex from "./components/parcelex";
import StoreCard from "./components/storeCard";
import Login from "./components/login";
import Contact from "./components/contact";
import Terms from "./components/terms";
import { ThemeProvider } from "./contexts/ThemeContext";

function App() {

  return (
    <ThemeProvider>
      <head>
        <link rel="icon" href="./favicon.png" />
      </head>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/about" element={<About />} />
          <Route path="/parcelex" element={<Parcelex />} />
          <Route path="/storeCard" element={<StoreCard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;